import { createStyles, Flex, UnstyledButton } from "@mantine/core"
import { Header } from "widgets/header"
import { Footer } from "widgets/footer"
import React, { useEffect, useRef } from "react"
// import { BannerStore } from "../store"
import { observer } from "mobx-react-lite"
import { API_URL } from "../../shared/config"
import { Link } from "react-router-dom"
import Autoplay from "embla-carousel-autoplay"
import AutoHeight from "embla-carousel-auto-height"
import { Carousel } from "@mantine/carousel"

// ----------------------------------------------------------------------

const useStyles = createStyles((theme) => ({
  rootWrapper: {
    boxSizing: "border-box",
    minWidth: 320,
    minHeight: "100vh",
  },

  wrapper: {
    display: "flex",
    boxSizing: "border-box",
  },

  main: {
    flex: 1,
    backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    boxSizing: "border-box",
    width: "100vw",
  },

  banner: {
    width: "100%",
    maxWidth: 1440,
    margin: "0 auto",
  },

  bannerImg: {
    width: "100%",
    height: "auto",
    objectFit: "contain",
  },

  carouselWrapper: {
    width: "100%",
    maxWidth: 1440,
    padding: "16px 16px 0",
    // position: "relative",
    margin: "0 auto",
    // paddingBottom: "14.16%",
  },
}))

// ----------------------------------------------------------------------

export type Props = {
  children: React.ReactNode
};

export const MainLayout = observer(function MainLayout({ children }: Props) {
  const { classes } = useStyles()
  // const { getActiveBanner, activeBanner } = BannerStore
  //
  // useEffect(() => {
  //   void getActiveBanner()
  // }, [ getActiveBanner ])
  const autoplay = useRef(Autoplay({ delay: 7000 }))

  return (
    <section className={classes.rootWrapper}>
      {/*{activeBanner && (*/}
      {/*  <div className={classes.banner}>*/}
      {/*    <UnstyledButton component={Link} to={activeBanner.url} target="_blank">*/}
      {/*      <img*/}
      {/*        alt="Баннер"*/}
      {/*        className={classes.bannerImg}*/}
      {/*        src={`${API_URL}/media/${activeBanner.image}`}*/}
      {/*      />*/}
      {/*    </UnstyledButton>*/}
      {/*  </div>*/}
      {/*)}*/}

      <Flex align="flex-start" className={classes.carouselWrapper}>
        <Carousel
          mx="auto"
          height="100%"
          // sx={{ flex: 1 }}
          slideSize="100%"
          align="start"
          slideGap="xs"
          loop
          draggable={false}
          withControls={false}
          plugins={[ autoplay.current, AutoHeight() ]}
        >
          <Carousel.Slide>
            <UnstyledButton
              component={Link}
              to="https://confpage.bodycoach.pro/?utm_source=citadel"
              target="_blank"
            >
              <img
                alt="Баннер конференций"
                className={classes.bannerImg}
                src="https://academy.bodycoach.pro/images/banners/01conf1224.png"
              />
            </UnstyledButton>
          </Carousel.Slide>
          <Carousel.Slide>
            <UnstyledButton
              component={Link}
              to="https://nutritionist.bodycoach.pro/?utm_source=citadel"
              target="_blank"
            >
              <img
                alt="Баннер конференций"
                className={classes.bannerImg}
                src="https://academy.bodycoach.pro/images/banners/01nutr1224.png"
              />
            </UnstyledButton>
          </Carousel.Slide>
        </Carousel>
      </Flex>

      <Header/>
      <section className={classes.wrapper}>
        <main className={classes.main}>{children}</main>
      </section>
      <Footer/>
    </section>
  )
})
