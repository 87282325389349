export const API_ENDPOINTS = {
  auth: {
    login: "/auth/login",
    logout: "/auth/logout",
    refresh: "/auth/refresh",
  },
  users: {
    me: "/users/me",
  },
  instructors: "/instructors",
  articles: "/articles",
  scheduleEvents: "/schedule-events",
  scheduleEventDrafts: "/schedule-events/drafts",
  banners: "/banners",
  mail: "/mail",
  pageContent: "/pageContent",
}
