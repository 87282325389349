import { lazy } from "react"
import { Navigate, Outlet } from "react-router-dom"
import { AuthGuard } from "app/hocs/AuthGuard"
import { DashboardLayout } from "../layouts/dashboard"
import { RoleBasedGuard } from "../hocs/RoleBasedGuard"

// ----------------------------------------------------------------------

export const InstructorTablePage = lazy(() => import("pages").then(module => ({ default: module.InstructorTablePage })))
export const InstructorEditPage = lazy(() => import("pages").then(module => ({ default: module.InstructorEditPage })))
export const ArticleTablePage = lazy(() => import("pages").then(module => ({ default: module.ArticleTablePage })))
export const ArticleEditPage = lazy(() => import("pages").then(module => ({ default: module.ArticleEditPage })))
export const ScheduleTablePage = lazy(() => import("pages").then(module => ({ default: module.ScheduleTablePage })))
export const ScheduleEditPage = lazy(() => import("pages").then(module => ({ default: module.ScheduleEditPage })))
export const BannerTablePage = lazy(() => import("pages").then(module => ({ default: module.BannerTablePage })))
export const BannerEditPage = lazy(() => import("pages").then(module => ({ default: module.BannerEditPage })))
export const AboutPage = lazy(() => import("pages").then(module => ({ default: module.AboutPage })))

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: "/dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Outlet/>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <Navigate to="/dashboard/blog" replace/>, index: true },
      {
        path: "instructors",
        children: [
          {
            element: (
              <RoleBasedGuard roles={[ "admin" ]}>
                <InstructorTablePage/>
              </RoleBasedGuard>
            ), index: true,
          },
          {
            path: "instructor",
            element: (
              <RoleBasedGuard roles={[ "admin" ]}>
                <InstructorEditPage/>
              </RoleBasedGuard>
            ),
          },
          {
            path: "instructor/:instructorId",
            element: (
              <RoleBasedGuard roles={[ "admin" ]}>
                <InstructorEditPage/>
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "blog",
        children: [
          {
            element: (
              <RoleBasedGuard roles={[ "admin", "moderator" ]}>
                <ArticleTablePage/>
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: "article",
            element: (
              <RoleBasedGuard roles={[ "admin", "moderator" ]}>
                <ArticleEditPage/>
              </RoleBasedGuard>
            ),
          },
          {
            path: "article/:articleId",
            element: (
              <RoleBasedGuard roles={[ "admin", "moderator" ]}>
                <ArticleEditPage/>
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "schedule",
        children: [
          {
            element: (
              <RoleBasedGuard roles={[ "admin" ]}>
                <ScheduleTablePage/>
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: "event",
            element: (
              <RoleBasedGuard roles={[ "admin" ]}>
                <ScheduleEditPage/>
              </RoleBasedGuard>
            ),
          },
          {
            path: "event/:scheduleEventId",
            element: (
              <RoleBasedGuard roles={[ "admin" ]}>
                <ScheduleEditPage/>
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "banners",
        children: [
          {
            element: (
              <RoleBasedGuard roles={[ "admin" ]}>
                <BannerTablePage/>
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: "banner",
            element: (
              <RoleBasedGuard roles={[ "admin" ]}>
                <BannerEditPage/>
              </RoleBasedGuard>
            ),
          },
          {
            path: "banner/:bannerId",
            element: (
              <RoleBasedGuard roles={[ "admin" ]}>
                <BannerEditPage/>
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "about",
        children: [
          {
            element: (
              <RoleBasedGuard roles={[ "admin" ]}>
                <AboutPage/>
              </RoleBasedGuard>
            ),
            index: true,
          },
        ],
      },
    ],
  },
]
