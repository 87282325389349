import { observer } from "mobx-react-lite"
import { Link, useLocation } from "react-router-dom"
import { createStyles, Navbar as MantineNavbar, Group, rem, NavLink } from "@mantine/core"
import { IconUsersGroup, IconArticle, IconFlag3, IconHome2, IconLogout, IconCalendar, IconBook2 } from "@tabler/icons-react"
import { Logo } from "shared/ui/Logo"
import { DashboardNavbarStore, AuthStore } from "app/store"
import { PATHS } from "shared/lib/paths"

// ----------------------------------------------------------------------

const useStyles = createStyles((theme) => ({
  content: {
    maxWidth: "100vw",
    maxHeight: "100vh",
  },

  header: {
    paddingBottom: theme.spacing.md,
    marginBottom: `calc(${theme.spacing.md} * 1.5)`,
    borderBottom: `${rem(1)} solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]}`,

    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  footer: {
    paddingTop: theme.spacing.md,
    marginTop: theme.spacing.md,
    borderTop: `${rem(1)} solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]}`,
  },

  link: {
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,
  },
}))

// ----------------------------------------------------------------------

const routes = [
  { linkTo: PATHS.DASHBOARD.BLOG.ROOT, label: "Блог", Icon: IconArticle, roles: [ "admin", "moderator" ] },
  { linkTo: PATHS.DASHBOARD.INSTRUCTORS.ROOT, label: "Преподаватели", Icon: IconUsersGroup, roles: [ "admin" ] },
  { linkTo: PATHS.DASHBOARD.SCHEDULE.ROOT, label: "Расписание", Icon: IconCalendar, roles: [ "admin" ] },
  {
    linkTo: PATHS.DASHBOARD.BANNERS.ROOT,
    label: "Баннеры",
    description: "Реклама на главной",
    Icon: IconFlag3,
    roles: [ "admin" ],
  },
  { linkTo: PATHS.DASHBOARD.ABOUT.ROOT, label: "Об организации", Icon: IconBook2, roles: [ "admin" ] },
]

// ----------------------------------------------------------------------

export const DashboardNavbar = observer(() => {
  const { classes } = useStyles()
  const { pathname } = useLocation()

  const { logout, user } = AuthStore
  const currentRole = user ? user.role : "unknown"

  const closeNavbar = () => {
    document.body.classList.remove("no-scroll")
    void DashboardNavbarStore.setOpened(false)
  }
  const handleLogout = () => {
    void closeNavbar()
    void logout()
  }

  const links = routes.map(({ label, Icon, description, linkTo, roles }) => (
    <NavLink
      disabled={!roles.includes(currentRole)}
      key={linkTo}
      className={classes.link}
      component={Link}
      label={label}
      icon={<Icon stroke={1.5}/>}
      description={description}
      to={linkTo}
      active={pathname.includes(linkTo)}
      onClick={closeNavbar}
    />
  ))

  return (
    <MantineNavbar
      className={classes.content}
      fixed
      hiddenBreakpoint="sm"
      hidden={!DashboardNavbarStore.isOpened}
      width={{ sm: 250, lg: 320 }}
      p="md"
    >
      <MantineNavbar.Section grow>
        <Group className={classes.header} position="apart">
          <Logo/>
        </Group>
        {links}
      </MantineNavbar.Section>

      <MantineNavbar.Section className={classes.footer}>
        <NavLink
          component={Link}
          className={classes.link}
          label="На главную"
          icon={<IconHome2 stroke={1.5}/>}
          to={PATHS.ROOT}
          onClick={closeNavbar}
        />
        <NavLink onClick={handleLogout} className={classes.link} label="Выход" icon={<IconLogout stroke={1.5}/>}/>
      </MantineNavbar.Section>
    </MantineNavbar>
  )
})
